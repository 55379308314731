import styled, { css } from 'styled-components'

const Title = styled.h4`
  font-family:"Larsseit-Bold";
  margin-bottom:5px; 
  color:#262626;
${props => props.medium && css`
  font-size: 26px; 
  line-height: 35px;`
  } 
  ${props => props.large && css`
  font-size: 32px; 
  line-height: 35px;`
  } 
`
export default Title;

