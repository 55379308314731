import React from 'react'
import Layout from '../components/layout'
import contact from '../images/contact/contact.svg'
import { Section, Wrapper } from '../components/baseStyles'
import Paragraph from '../components/paragraph'
import Title from '../components/title'
import SEO from '../components/seo'

const Conditions = ({ location, pageContext: { locale } }) => {
  const isConditions = true
  return (
    <Layout
      heroImage={contact}
      isConditions={isConditions}
      title="Conditions"
      text="conditions"
      locale={locale}
    >
      <SEO title={locale==='hr' ?  "Uvjeti" : "Terms andConditions"} keywords={['Litto']} lang={locale} />

      <Wrapper>
        <Section>
          <Title large>Koliko korisnici plaćaju Airbnb za događaje?</Title>
          <Paragraph offset="35px" medium>
            Potpuno vam je besplatno pomoću Litto za događaje gostima pronaći
            izvrsne smještaje koji odgovaraju njihovim potrebama
          </Paragraph>
          <Title large>Koliko korisnici plaćaju Airbnb za događaje?</Title>
          <Paragraph offset="35px" medium>
            Potpuno vam je besplatno pomoću Litto za događaje gostima pronaći
            izvrsne smještaje koji odgovaraju njihovim potrebama
          </Paragraph>
          <Title large>Kako funkcionira</Title>
          <Paragraph offset="35px" medium>
            Potpuno vam je besplatno pomoću Litto za događaje gostima pronaći
            izvrsne smještaje koji odgovaraju njihovim potrebama
          </Paragraph>
          <Title large>Kako funkcionira</Title>
          <Paragraph offset="35px" medium>
            Lorem Ipsum is simply dummy text of the printing and typesetting i
            ndustry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </Paragraph>
          <Title large>Kako funkcionira</Title>
          <Paragraph offset="100px" medium>
            Remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </Paragraph>
        </Section>
      </Wrapper>
    </Layout>
  )
}

export default Conditions
